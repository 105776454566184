module.exports = ({ API_URL }) => {
    return {
        authors: {
            all: (perPage = 1024) =>
                `${API_URL}/authors?per-page=${perPage}&expand=translations,media`,
        },
        contact: `${API_URL}/contact`,
        consents: {
            all: (perPage = 1024) =>
                `${API_URL}/consents?per-page=${perPage}&expand=translations,media`,
        },
        posts: {
            all: (perPage = 1024) =>
                `${API_URL}/posts?per-page=${perPage}&expand=translations,media,author,author.media,tags`,
        },
        tags: {
            all: (perPage = 1024) => `${API_URL}/tags?per-page=${perPage}`,
        },
        siteSettings: `${API_URL}/settings/site`,
        slogans: {
            all: (perPage = 1024) => `${API_URL}/sentences?per-page=${perPage}`,
        },
    };
};
