import React, { ForwardedRef } from 'react';

import { button, icon } from './chevron-button.module.scss';
import ChevronIcon from '../../assets/images/svg/chevron.svg';

interface IChevronButtonProps {
    className?: string;
    ariaLabel?: string;
    rotate?: number;
    onClick?(): any;
}

const ChevronButton = React.forwardRef(
    (
        { ariaLabel = '', onClick, className, rotate = 0 }: IChevronButtonProps,
        ref: ForwardedRef<HTMLButtonElement>
    ) => {
        return (
            <button
                ref={ref}
                className={`${button} ${className}`}
                aria-label={ariaLabel}
                style={{
                    transform: `rotate(${rotate}deg)`,
                }}
            >
                <ChevronIcon className={icon} />
            </button>
        );
    }
);

export default ChevronButton;
