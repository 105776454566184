// extracted by mini-css-extract-plugin
export var aside = "content-layout-module--aside--0ar10";
export var asidePosts = "content-layout-module--aside-posts--2TKsu";
export var chevrons = "content-layout-module--chevrons--qeI-h";
export var container = "content-layout-module--container--JiX4-";
export var content = "content-layout-module--content--2+5Gi";
export var contentBox = "content-layout-module--content-box--bvQJe";
export var contentScroll = "content-layout-module--content-scroll--RIbba";
export var contentScrollBox = "content-layout-module--content-scroll-box--gG0BZ";
export var controls = "content-layout-module--controls--x3aHi";
export var modalDesktop = "content-layout-module--modal-desktop--6PZtY";
export var modalMobile = "content-layout-module--modal-mobile--+KD0H";