import React, { useEffect, useState } from 'react';
import { motion, Variants } from 'framer-motion';
import { useLocation } from '@reach/router';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    layer,
    layerClose,
    container,
    error,
    closeButton,
    fixed,
    absolute,
} from './modal.module.scss';
import { useModalContext } from '../../contexts/modal-context';
import { IModal } from '../../models/modal.model';

import CloseButton from '../atoms/close-button';
import Loader from '../atoms/loader';

interface IModalProps {
    className?: string;
    position?: 'fixed' | 'absolute';
}

const layerVariants: Variants = {
    closed: {
        opacity: 0,
        transition: { duration: 0.2, delay: 0.2 },
    },
    open: {
        opacity: 1,
        transition: { duration: 0.2 },
    },
};

const messageVariants: Variants = {
    closed: {
        scale: 0,
        y: '-100%',
        transition: { duration: 0.2 },
    },
    open: {
        scale: 1,
        y: 0,
        transition: { duration: 0.2, delay: 0.2, type: 'spring' },
    },
};

const Modal: React.FC<IModalProps> = ({ className = '', position = 'absolute' }) => {
    const { t } = useI18next();
    const { pathname } = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const { modal, removeModal } = useModalContext();
    const { type = 'info', message = '' } = modal || {};

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleAnimationComplete = (variant: string) => {
        if (variant === 'open') return;
        removeModal();
    };

    useEffect(() => {
        if (modal) {
            setIsOpen(true);
        }
    }, [modal]);

    useEffect(() => {
        setIsOpen(false);
    }, [pathname]);

    if (!modal) return null;

    return (
        <motion.div
            className={`${layer} ${className} ${getTypeClass(type)} ${getPositionClass(position)}`}
            variants={layerVariants}
            initial="closed"
            animate={isOpen ? 'open' : 'closed'}
            onAnimationComplete={handleAnimationComplete}
        >
            {type !== 'loading' && (
                <button
                    className={layerClose}
                    onClick={handleClose}
                    aria-label={t('button.close')}
                    tabIndex={-1}
                />
            )}
            {type === 'loading' ? (
                <Loader isAbsolute={true} />
            ) : (
                <motion.div
                    className={container}
                    variants={messageVariants}
                    initial="closed"
                    animate={isOpen ? 'open' : 'closed'}
                >
                    <CloseButton
                        onClick={handleClose}
                        className={closeButton}
                        color={type === 'error' ? 'light' : 'dark'}
                    />
                    <p>{message}</p>
                </motion.div>
            )}
        </motion.div>
    );
};

function getPositionClass(position: IModalProps['position']) {
    if (position === 'fixed') return fixed;
    return absolute;
}

function getTypeClass(type: IModal['type']): string {
    if (type === 'error') {
        return error;
    }
    return '';
}

export default Modal;
