import React from 'react';
import { Field, FieldConfig, ErrorMessage, useField } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';

import { container, labelBox, input, inputError, error } from './input.module.scss';

import Error from './error';

interface IInputProps {
    className?: string;
    name: string;
    label?: string;
    placeholder?: string;
    as?: FieldConfig['as'];
    rows?: number;
}

const Input: React.FC<IInputProps & Record<string, any>> = ({
    className = '',
    name,
    as,
    rows = 4,
    label = '',
    placeholder = '',
    ...rest
}) => {
    const [, meta] = useField(name);
    const hasError = !!meta.error && meta.touched;
    return (
        <div className={`${container} ${className}`}>
            {(label || placeholder) && (
                <label className={labelBox} htmlFor={name}>
                    {label || placeholder}
                </label>
            )}
            <ErrorMessage name={name} className={error} component={Error} />
            <Field
                as={as === 'textarea' ? TextareaAutosize : ''}
                {...(as === 'textarea' ? { minRows: rows } : {})}
                id={name}
                name={name}
                className={`${input} ${hasError ? inputError : ''}`}
                placeholder={placeholder}
                {...rest}
            />
        </div>
    );
};

export default Input;
