import React, { useRef } from 'react';
import { motion, Variants } from 'framer-motion';
import { navigate } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    content,
    contentScroll,
    contentBox,
    controls,
    aside,
    asidePosts,
    chevrons,
    contentScrollBox,
    modalDesktop,
    modalMobile,
} from './content-layout.module.scss';
import { PAGE_CHANGE_DURATION } from '../config/consts';
import { breakpoints } from '../config/breakpoints';
import useWindowWidth from '../hooks/use-window-width';
import { usePostCards } from '../contexts/post-cards-context';

import MainLayout, { IMainLayoutProps } from './main-layout';
import ScrollContainer from '../components/hoc/scroll-container';
import AsidePosts from '../components/organisms/aside-posts';
import CloseButton from '../components/atoms/close-button';
import ChevronButton from '../components/atoms/chevron-button';
import Modal from '../components/hoc/modal';

interface IContentLayoutProps extends IMainLayoutProps {
    excludeAsidePostId?: number;
    postMainTagId?: number;
}

const contentVariants: Variants = {
    start: {
        x: '-120%',
        transition: {
            duration: PAGE_CHANGE_DURATION,
        },
    },
    done: {
        x: 0,
        transition: {
            duration: PAGE_CHANGE_DURATION,
        },
    },
    finish: {
        x: '-120%',
        transition: {
            duration: PAGE_CHANGE_DURATION,
        },
    },
};

const ContentLayout: React.FC<IContentLayoutProps> = ({
    SEOProps,
    excludeAsidePostId,
    postMainTagId,
    className,
    children,
}) => {
    const { t } = useI18next();
    const postCards = usePostCards({ tagId: postMainTagId, excludeId: excludeAsidePostId });
    const windowWidth = useWindowWidth() || breakpoints.iPad;
    const backwardRef = useRef(null);
    const forwardRef = useRef(null);

    return (
        <MainLayout SEOProps={SEOProps}>
            <div className={container}>
                <motion.div
                    className={content}
                    variants={contentVariants}
                    initial="start"
                    animate="done"
                    exit="finish"
                >
                    <div className={contentScrollBox}>
                        <ScrollContainer
                            className={contentScroll}
                            axis="y"
                            isDisabled={windowWidth < breakpoints.iPad}
                            backwardRef={backwardRef}
                            forwardRef={forwardRef}
                        >
                            <div className={`${contentBox} ${className}`}>{children}</div>
                        </ScrollContainer>
                        <Modal className={modalDesktop} position="absolute" />
                    </div>
                    <div className={controls}>
                        <CloseButton ariaLabel={t('button.back')} onClick={() => navigate(-1)} />
                        <div className={chevrons}>
                            <ChevronButton
                                ref={backwardRef}
                                rotate={0}
                                ariaLabel={t('button.scrollUp')}
                            />
                            <ChevronButton
                                ref={forwardRef}
                                rotate={180}
                                ariaLabel={t('button.scrollDown')}
                            />
                        </div>
                    </div>
                </motion.div>
                <ScrollContainer
                    axis="y"
                    className={aside}
                    isDisabled={windowWidth < breakpoints.iPad}
                >
                    <AsidePosts className={asidePosts} postCards={postCards} />
                </ScrollContainer>
                <Modal className={modalMobile} position="fixed" />
            </div>
        </MainLayout>
    );
};

export default ContentLayout;
