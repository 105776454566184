import React from 'react';

import { error } from './error.module.scss';

interface IErrorProps {
    className?: string;
}

const Error: React.FC<IErrorProps> = ({ className = '', children }) => {
    return <p className={`${error} ${className}`}>{children}</p>;
};

export default Error;
