import axios from 'axios';
import endpoints from '../config/endpoints';

const API_URL = process.env.API_URL;

interface IContactData {
    email: string;
    phone: string;
    content: string;
}

export function postContact(data: IContactData): Promise<any> {
    return axios.post(endpoints({ API_URL }).contact, data, { withCredentials: true });
}
