import React, { ButtonHTMLAttributes } from 'react';

import { button, pill, small, content, hiddenContent } from './button.module.scss';

interface IButtonProps {
    className?: string;
    type?: ButtonHTMLAttributes<IButtonProps>['type'];
    radiusType?: 'rectangle' | 'pill';
    size?: 'small' | 'normal';
    text?: string;
    onClick?(): void;
}

const Button: React.FC<IButtonProps> = ({
    className = '',
    onClick,
    type,
    radiusType = 'pill',
    size = 'normal',
    text = '',
}) => {
    return (
        <button
            className={`
                ${button} 
                ${className}
                ${getRadiusTypeClass(radiusType)}
                ${getSizeClass(size)}
            `}
            {...(type ? { type } : {})}
            {...(onClick ? { onClick } : {})}
        >
            <span className={hiddenContent}>{text}</span>
            <span className={content}>{text}</span>
        </button>
    );
};

function getRadiusTypeClass(radiusType: IButtonProps['radiusType']): string {
    if (radiusType === 'pill') {
        return pill;
    }
    return '';
}

function getSizeClass(size: IButtonProps['size']): string {
    if (size === 'small') {
        return small;
    }
    return '';
}

export default Button;
