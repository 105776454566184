import React from 'react';
import { Form, FormikProps } from 'formik';

import { form, globalError } from './formik-form.module.scss';
import { statusMap } from '../../config/status-map';

import Error from '../atoms/form/error';

interface FormikFormProps {
    className?: string;
    formik: FormikProps<any>;
}

const FormikForm: React.FC<FormikFormProps> = ({ className = '', formik, children }) => {
    const { status, errors } = formik;
    return (
        <Form className={`${form} ${className}`}>
            {children}
            {status === statusMap.error && errors.global && (
                <Error className={globalError}>{errors.global}</Error>
            )}
        </Form>
    );
};

export default FormikForm;
