import React from 'react';
import { motion, Variants } from 'framer-motion';

import { list, item } from './aside-posts.module.scss';
import { PAGE_CHANGE_DURATION } from '../../config/consts';
import { IPostCard } from '../../models/post.model';

import PostCard from '../molecules/post-card';

interface IAsidePostsProps {
    className?: string;
    postCards: IPostCard[];
}

const AsidePosts: React.FC<IAsidePostsProps> = ({ className = '', postCards }) => {
    return (
        <motion.ul
            className={`${list} ${className}`}
            variants={listVariants}
            initial="start"
            animate="done"
            exit="finish"
        >
            {postCards.map((postCard, index) => {
                return (
                    <motion.li
                        key={`aside-post-item-${postCard.articleId}-${index}`}
                        className={item}
                        variants={itemVariants}
                    >
                        <PostCard
                            key={`aside-post-card-${postCard.articleId}-${index}`}
                            postCard={postCard}
                            coverVersion="horizontal"
                        />
                    </motion.li>
                );
            })}
        </motion.ul>
    );
};

const listVariants = {
    start: {},
    done: {
        transition: {
            staggerChildren: 0.1,
        },
    },
    finish: {},
};

const itemVariants: Variants = {
    start: {
        x: '200%',
        transition: {
            duration: PAGE_CHANGE_DURATION,
        },
    },
    done: {
        x: 0,
        transition: {
            duration: PAGE_CHANGE_DURATION,
        },
    },
    finish: {
        x: '200%',
        transition: {
            duration: PAGE_CHANGE_DURATION,
        },
    },
};

export default AsidePosts;
