import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { title, subtitle, form, addressGrid, mapRatio } from './contact-page.module.scss';
import { OFFICE_GOOGLE_MAPS_POSITION } from '../config/consts';
import { businessCards } from '../config/business-cards';
import { translationKeys } from '../config/translation-keys';
import { statusMap } from '../config/status-map';
import { ISitePageContext } from '../models/site-page.model';
import { useTranslation } from '../hooks/use-translation';
import { useModalContext } from '../contexts/modal-context';

import { Map, MapWrapper, IMapProps } from '../components/organisms/map';
import ContentLayout from '../layouts/content-layout';
import ContactForm, { IContactFormProps } from '../components/organisms/contact-form';
import BusinessCard from '../components/molecules/business-card';

interface IContactPageProps {
    readonly pageContext: ISitePageContext;
}

const ContactPage: React.FC<IContactPageProps> = ({ pageContext }) => {
    const { t } = useI18next();
    const { label } = useTranslation(pageContext, translationKeys.pageContext);
    const { addModal } = useModalContext();

    const handleLoading: IContactFormProps['onLoading'] = helpers => {
        helpers.setStatus(statusMap.loading);
        addModal({
            type: 'loading',
        });
    };

    const handleSuccess: IContactFormProps['onSuccess'] = helpers => {
        helpers.setStatus(statusMap.idle);
        helpers.resetForm();
        addModal({
            type: 'info',
            message: t('form.contact.success'),
        });
    };

    const handleError: IContactFormProps['onError'] = helpers => {
        helpers.setStatus(statusMap.error);
        addModal({
            type: 'error',
            message: t('form.contact.error'),
        });
    };

    return (
        <ContentLayout SEOProps={{ title: label }}>
            <h1 className={title}>{t('contact.title')}</h1>
            <h2 className={subtitle}>{t('contact.subtitle')}</h2>
            <ContactForm
                className={form}
                onLoading={handleLoading}
                onSuccess={handleSuccess}
                onError={handleError}
            />
            <div className={addressGrid}>
                {businessCards.map(card => {
                    return (
                        <BusinessCard
                            key={`business-card-${card.groupMember}`}
                            businessCard={card}
                            logoFileType="svg"
                        />
                    );
                })}
            </div>
            <MapWrapper>
                <Map ratioClass={mapRatio} options={mapOptions} markers={mapMarkers} />
            </MapWrapper>
        </ContentLayout>
    );
};

const mapOptions: IMapProps['options'] = {
    zoom: 17,
    center: OFFICE_GOOGLE_MAPS_POSITION,
};

const mapMarkers: IMapProps['markers'] = [{ position: OFFICE_GOOGLE_MAPS_POSITION }];

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default ContactPage;
